import { H1 } from '@repo/ui/components/Typography.js'
import { cn } from '@repo/ui/utils/utils'
import type { ComponentProps } from 'react'

export const PageTitle = ({ ...props }: ComponentProps<'h1'>) => {
  return <H1 {...props} className={cn('text-2xl', props.className)} />
}

export const PageSubtitle = ({ children }: { children: React.ReactNode }) => {
  return <div className="text-muted-foreground">{children}</div>
}
